import React from 'react'

// Deps
//import parse from 'html-react-parser';
import workData from 'data/works'

// Partials
import Link from 'views/partials/link'
import Image from 'views/partials/image'

const Works = (props) => {
	return (
		<article className={'page content works' + (props.show ? ' show' : '')}>
			<h1 className="content-title">Works</h1>

			<ul className="works-list">
				{Object.keys(workData).map((key) => {
					const item = workData[key];
					return (
						<li key={key} className="list-item">
							<Link className="item-link" href="workDetail" params={{slug: key}}>
								<div className="item-preview">
									<Image
										className="preview-logo"
										bg contain
										src={'/assets/images/works/logo/' + key + '.svg'} />
									<Image
										className="preview-image"
										bg
										src={'/assets/images/works/thumb/' + key + '.jpg'} />
								</div>

								<div className="item-text">
									<h2 className="text-title">{item.title}</h2>
									<strong className="text-type">{item.type}</strong>
								</div>
							</Link>
						</li>
					);
				})}
			</ul>
		</article>
	)
}

export default Works