import React, { useState, useEffect, useRef } from 'react'

//Deps
import imageLoad from 'functions/image-load'
import omit from 'lodash/omit'
import { connect } from "react-redux"
import { useInView } from 'react-intersection-observer'

const useProps = (rawProps, loading) => {
	let props = omit(rawProps, ['src', 'src2x', 'mobileSrc', 'mobileState', 'lazy', 'bg', 'abs', 'contain', 'dispatch']);

	props.className = 'image ' + rawProps.className;

	if(rawProps.bg) {
		props.className += ' imagewrap bg';

		if (rawProps.contain) {
			props.className += ' contain';
		}

		if (rawProps.abs) {
			props.className += ' abs';
		}

		props.alt = undefined;
	}

	if(loading) {
		props.className += ' image-loading';
	}
	// if(inView) {
	// 	props.className += ' in-view';
	// }

	return props;
}

const useSrc = (props) => {
	return ((props.mobileState && props.mobileSrc) ? props.mobileSrc : ((window.$yt.retinaDevice && props.src2x) ? props.src2x : props.src));
}

const Image = (props) => {
	const [loading, setLoading] = useState(true);
	
	const _mounted = useRef(false);

	const [ref, inView/*, entry*/] = useInView({
		threshold: 0,
		rootMargin: '5%',
		triggerOnce: true,
		disabled: !props.lazy,
	});
	const elemProps = useProps(props, loading);
	const src = useSrc(props);


	useEffect(() => {
		_mounted.current = true;

		return(() => {
			_mounted.current = false;
		});
	}, [])

	useEffect(() => {
		setLoading(true);

		if((!props.lazy || inView) && src){
			imageLoad(src, function () {
				if (_mounted.current) {
					setLoading(false);
					if (props.onLoad) {
						props.onLoad();
					}
				}
			});
		}
	}, [src, props, inView])

	let ElemTag = props.bg ? BgImageElem : ImageElem;

	return (
		<ElemTag
			containerProps={elemProps}
			inView={props.lazy ? inView : true}
			lazy={props.lazy}
			src={src}
			ref={ref} />
	);
}

const ImageElem = React.forwardRef((props, ref) => {
	return (
		<img
			ref={ref}
			{...props.containerProps}
			data-src={!props.inView ? props.src : undefined}
			src={props.inView ? props.src : undefined}
			alt={props.containerProps.alt} />
	)
});

const BgImageElem = React.forwardRef((props, ref) => {
	return (
		<div
			ref={ref}
			{...props.containerProps}>
				<div
					className="imagewrap-image"
					style={props.inView ? {backgroundImage: 'url(' + props.src + ')'} : undefined}>
				</div>
		</div>
	)
});

Image.defaultProps = {
	className: '',
	alt: "Görsel",
	mobileSrc: false,
	contain: false,
	bg: false,
	lazy: true, // No lazyLoad
};

const mapStateToProps = state => {
	return { mobileState: state.site.mobile };
};

export default connect(mapStateToProps)(Image);