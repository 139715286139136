import React from 'react';

// Partials
import Link from 'views/partials/link'

const SocialLinks = ({ className }) => {
    return (
        <nav className={`social-links ${className ?? ''}`}>
            <Link
                type="a"
                className="links-link"
                href="mailto:yutonet@gmail.com"
                target="_blank"
                rel="noopener">
                <i className="icon-mail"></i>
            </Link>
            <Link
                type="a"
                className="links-link"
                href="https://www.linkedin.com/in/yutonet/"
                target="_blank"
                rel="noopener">
                <i className="icon-linkedin"></i>
            </Link>
            <Link
                type="a"
                className="links-link"
                href="https://www.github.com/yutonet"
                target="_blank"
                rel="noopener">
                <i className="icon-github"></i>
            </Link>
        </nav>
    )
}

export default SocialLinks;