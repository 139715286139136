import React from 'react'

// Partials
import Image from 'views/partials/image'
import Link from 'views/partials/link'
import SocialLinks from 'views/partials/social-links';

// Static Assets
import Logo from 'assets/images/logo.svg'

const Home = (props) => {
	return (
		<article className={'page home' + (props.show ? ' show' : '')}>
			<div className="home-wrap">
				<Image className="home-logo" src={Logo} />

				<nav className="home-menu">
					<div className="menu-item">
						<Link className="item-link" href="about" />
					</div>
					<div className="menu-item">
						<Link className="item-link" href="works" />
					</div>
					<div className="menu-item">
						<Link className="item-link" href="contact" />
					</div>
				</nav>

				<SocialLinks className="home-social" />
			</div>
		</article>
	)
}

export default Home