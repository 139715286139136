import React from 'react';

// Utilities
import history from './utils/history'

// Controllers
import Navigator from './controllers/navigator'

// Deps
import { Router, Switch, Route } from 'react-router-dom'

const App = () => {
	return (
		<Router history={history}>
			<Switch><Route component={Navigator} /></Switch>
		</Router>
	)
}

export default App;