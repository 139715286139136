import React, { useState, useEffect, useRef } from 'react'

// Deps
import parse from 'html-react-parser'
import workData from 'data/works'
import { set404 } from 'controllers/navigator'
import pxToRem from 'functions/px-to-rem'
import { setHead, setTitle, setDescription } from 'utils/head'

// Partials
import Link from 'views/partials/link'
import Image from 'views/partials/image'

const Works = (props) => {
	const [data, setData] = useState(false);
	const [extend, setExtend] = useState(false);
	const [extendActive, setExtendActive] = useState(false);
	const [extendShow, setExtendShow] = useState(false);
	const [extendShown, setExtendShown] = useState(false);
	const [extendHeight, setExtendHeight] = useState(false);

	const extendImage = useRef();

	useEffect(() => {
		let newData = workData[props.match.params.slug];
		if(newData) {
			setData(newData);

			setHead([{
				key: "meta",
				props: {
					property: "og:image",
					content: window.location.origin + '/assets/images/works/full/' + props.match.params.slug + '.jpg',
				}
			}]);

			setTitle(newData.title);
			setDescription(newData.description);
		}
		else {
			set404();
		}
	}, [props.match.params.slug]);

	useEffect(() => {
		if(extend) {
			setExtendActive(true);

			setTimeout(() => {
				if(extendImage.current){
					setExtendHeight(pxToRem(extendImage.current.getBoundingClientRect().height));
					setExtendShow(true);

					setTimeout(function() {
						setExtendShown(true);
					}, 350);
				}
			}, 20);
		}
		else {
			setExtendShow(false);

			setTimeout(function() {
				setExtendActive(false);
				setExtendShown(false);
			}, 300);
		}
	}, [extend])

	if(data) {
		return (
			<article className={'page content work-detail' + (props.show ? ' show' : '')}>
				<div className="content-innerwrap">
					<Link className="detail-backlink" href="works" />
					<h1 className="content-title detail-title">{data.title}</h1>
					<div className="detail-info">
						<span className="info-type">{data.type}</span>
						<span className="info-date">{data.date}</span>
						<Link type="a" href={data.url} target="_blank" rel="noopener" className="info-link">{data.url}</Link>
					</div>

					<div className="detail-description content-text wysiwyg">
						{parse(data.description)}
					</div>

					<div className="detail-subinfo">
						<div className="subinfo-section subinfo-stack">
							<h2 className="section-title">Client</h2>

							<Link type="a" className="link" href={data.client.url} target="_blank" rel="noopener">{data.client.name}</Link>
						</div>

						<div className="subinfo-section subinfo-stack">
							<h2 className="section-title">Participation</h2>

							<span>{data.participation}</span>
						</div>

						<div className="subinfo-section subinfo-stack">
							<h2 className="section-title">Keywords</h2>

							<ul className="section-items">
								{data.stack.map((item, nth) => (
									<li className="items-item" key={nth}>{item}</li>
								))}
							</ul>
						</div>
					</div>

					<div className={'detail-preview' + (extendActive ? ' active' : '') + (extendShow ? ' show' : '') + (extendShown ? ' shown' : '')}>
						<button
							type="button"
							className="preview-innerwrap"
							style={(extendShow ? {paddingBottom: extendHeight + 'rem'} : undefined)}
							onClick={() => {setExtend(!extend)}}>
							<i className={'preview-extend icon-zoom-' + (extendActive ? 'out' : 'in')}></i>
							<img
								ref={extendImage}
								alt={data.title}
								className="preview-extendimage"
								src={'/assets/images/works/full/' + props.match.params.slug + '.jpg'} />
							<Image
								bg
								className="preview-slideimage"
								src={'/assets/images/works/full/' + props.match.params.slug + '.jpg'} />
						</button>
					</div>
				</div>
			</article>
		)
	}
	else { return false; }
}

export default Works