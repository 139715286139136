import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import store from './store/'
import { Provider } from 'react-redux'

import './assets/scss/app.scss';

//import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
	<Provider store={store}>
		<App />
	</Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

//serviceWorker.unregister();
