const imageLoad = (src, returnFunction, additionalData = null) => {
	let img = new Image();
	img.onload = function () {
		returnFunction(additionalData);
	}
	img.src = src;
	if (img.complete) {
		img.onload();
	}
}

export default imageLoad;