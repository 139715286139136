import React from 'react'

const ErrorPage = (props) => {
	return (
		<article className={'page content error' + (props.show ? ' show' : '')}>
			About
		</article>
	)
}

export default ErrorPage