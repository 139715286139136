import { getMobileState } from 'controllers/dom-watcher'

const initialState = {
	currentPage: {
		key: false,
		data: {}
	},
	mobile: getMobileState(),
	pageNotFound: false,
	pageError: false,
};

const siteReducer = (state = initialState, action) => {
	if (action.type === "SET_MOBILE") {
		return Object.assign({}, state, {
			mobile: action.payload
		});
	}
	else if (action.type === "SET_PAGE") {
		return Object.assign({}, state, {
			currentPage: action.payload
		});
	}
	else if (action.type === "SET_PAGE_NOT_FOUND") {
		return Object.assign({}, state, {
			pageNotFound: action.payload
		});
	}
	else if (action.type === "SET_PAGE_ERROR") {
		return Object.assign({}, state, {
			pageError: action.payload
		});
	}
	return state;
};

export default siteReducer;