export const setMobile = payload => ({
	type: "SET_MOBILE", payload
});

export const setPage = payload => ({
	type: "SET_PAGE", payload
});

export const setPageNotFound = payload => ({
	type: "SET_PAGE_NOT_FOUND", payload
});

export const setPageError = payload => ({
	type: "SET_PAGE_ERROR", payload
});