import React, { useState, useEffect } from 'react'

// Deps
import { connect } from "react-redux";
import store from "store";
import { setPage, setPageNotFound, setPageError } from "store/site/actions";
import routes from 'routes'
import { matchPath } from 'react-router-dom'
import config from 'config'

// Controllers
import DomWatcher from 'controllers/dom-watcher'

// Utils
import history from 'utils/history'
import { setTitle, setMeta, setHead, setDescription, clearHead } from 'utils/head'

// Pages
import Home from 'views/pages/home'
import About from 'views/pages/about'
import Works from 'views/pages/works'
import WorkDetail from 'views/pages/work-detail'
import Contact from 'views/pages/contact'
import NotFound from 'views/pages/not-found'
import ErrorPage from 'views/pages/error'

// Sections
import Navigation from 'views/sections/navigation'

const pageRegistry = {
	Home: Home,
	About: About,
	Works: Works,
	WorkDetail: WorkDetail,
	Contact: Contact,
	NotFound: NotFound,
	ErrorPage: ErrorPage,
}

const mapStateToProps = state => {
	return {
		currentPage: state.site.currentPage,
		pageNotFound: state.site.pageNotFound,
		pageError: state.site.pageError,
	};
};

class Navigator extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			currentRoute: history.location,
		}

		window.$yt = {
			retinaDevice: (window.devicePixelRatio > 1),
		};

		changePage();
	}

	componentDidMount() {
		window.dynamicHistory = history;
		let vm = this;

		history.listen(function (e) {
			window.prerenderReady = false;
			store.dispatch(setPageNotFound(false));
			store.dispatch(setPageError(false));

			if (e.hash !== (vm.state.currentRoute ? vm.state.currentRoute.hash : false)) {
				let hashEvent = new Event('hash-change');
				window.dispatchEvent(hashEvent);
			}
			vm.setState({ currentRoute: e });
			//let route = getRouteFromUrl(e.pathname, false, true);
			changePage();
		});
	}

	render() {
		return (
			<React.Fragment>
				<DomWatcher />
				<div className="wrapper">
					<Navigation />
					<div className="wrapper-innerwrap">
						{this.props.currentPage.key &&
							<PageRenderer currentPage={this.props.currentPage} />
						}
					</div>
				</div>
			</React.Fragment>
		)
	}
}

const useRoutes = () => {
	let list = [];

	for(let groupKey in routes){
		for(let routeKey in routes[groupKey]){
			let route = routes[groupKey][routeKey];

			list.push({...route, key: routeKey, group: groupKey, fullKey: groupKey+'.'+routeKey});
		}
	}

	return list;
}

const PageRenderer = (props) => {
	let routeList = useRoutes();

	return (
		<React.Fragment>
			{routeList.map((route) => {
				let active = route.fullKey === props.currentPage.fullKey
				return (<PageWrap
					key={route.fullKey}
					route={route}
					match={active ? props.currentPage.data.match : false}
					active={active} />)
			})}
		</React.Fragment>
	);
}

const PageWrap = (props) => {
	const [active, setActive] = useState(props.active);
	const [show, setShow] = useState(props.active);
	const [match, setMatch] = useState(props.match);

	useEffect(() => {
		if(props.match !== false) {
			setMatch(props.match);
		}
	}, [props.match])

	useEffect(() => {
		if(props.active && !active) {
			if(config.pageTransition) {
				setTimeout(function() {
					setActive(true);

					setTimeout(function() {
						setShow(true);
					}, 50 /*config.pageTransition*/);
				}, config.pageTransition);
			}
			else{
				setActive(true);
				setShow(true);
			}
		}
		else if(!props.active && active) {
			setShow(false);

			if(config.pageTransition) {
				setTimeout(function() {
					setActive(false);
				}, config.pageTransition);
			}
			else{
				setActive(false);
			}
		}
	}, [props.active, active])

	if(active) {
		const Component = pageRegistry[props.route.component];
		if(Component) {
			return <Component show={show} match={match} />;
		}
		else { console.warn('Navigator error: Component not found.'); return false; }
	}
	else {
		return false;
	}

}

export const changePage = (key = false, group = 'pages', opts = {}) => {
	let route = (key ? routes[group][key] : getRouteFromUrl(false, true));

	if (route.key) {
		key = route.key;
		group = route.groupKey;
	}

	if (key === 'notFound') {
		store.dispatch(setPageNotFound(true));
	}

	if (key === 'error') {
		store.dispatch(setPageError(true));
	}

	let pageData = {
		key: key,
		group: group,
		fullKey: group + "." + key,
		data: route,
	}
	if (store.getState().site.currentPage.fullKey !== group + '.' + key) {
		if(config.pageTransition) {
			setTimeout(function() {
				window.scroll(0, 0);
			}, config.pageTransition);
		}
		else {
			window.scroll(0, 0);
		}
		store.dispatch(setPage(pageData));

		// let hash = window.location.hash;

		// if (hash) {
		// 	setTimeout(function () {
		// 		let hashTarget = document.querySelector(hash)
		// 		if (hashTarget) {
		// 			hashTarget.scrollIntoView();
		// 		}
		// 	}, 500);
		// }
	}

	setTitle(route.title);
	if (route.description) {
		setDescription(route.description);
	}
	
	// Clear Head
	clearHead('http');
	setMeta((route.meta ? route.meta : false), opts.keepHead !== true);
	setHead((route.head ? route.head : false), opts.keepHead !== true);

	setHead([
		{
			key: "link",
			props: {
				rel: "canonical",
				href: window.location.href,
			}
		},
		{
			key: "meta",
			props: {
				property: "og:url",
				content: window.location.href,
			}
		}
	]);

	let status = (opts.statusCode ? opts.statusCode : (route.status ? route.status : 200));

	setHead([
		{
			key: "meta",
			props: {
				name: "prerender-status-code",
				content: status,
			}
		}
	], false, 'http');

	if(status === 301) {
		setHead([
			{
				key: "meta",
				props: {
					name: "prerender-header",
					content: window.location.href,
				}
			}
		], false, 'http');
	}

	if(route.autoReadyHead !== false) {
		setTimeout(function() {
			window.prerenderReady = true;
		}, 10);
	}
}

export function getRoute(key = false, group = 'pages') {
	let routeGroup = group;
	let keyParts = key.split('.');
	if (keyParts.length === 2) {
		routeGroup = keyParts[0];
		key = keyParts[1];
	}

	let target = routes[routeGroup][key];
	return (target ? target : false);
}

export const getRouteFromUrl = (url = false, includeCatch = false, getObject = true) => {
	if (url === false) { url = window.location.pathname.replace(/\/$/, ''); }
	let returnRoute = false;
	let catchRoute = false;
	Object.keys(routes).forEach((groupKey, index) => {
		let group = routes[groupKey];

		Object.keys(group).forEach((key, index) => {
			let route = routes[groupKey][key];
			if (route.path) {
				let match = matchPath(url, route.path);
				if (match && match.isExact) {
					returnRoute = (getObject ? {...route, key: key, groupKey: groupKey, match: match} : [key, groupKey]);
				}
			}
			else if (includeCatch && catchRoute === false) {
				catchRoute = (getObject ? {...route, key: key, groupKey: groupKey} : [key, groupKey]);
			}
		});
	});

	return (returnRoute ? returnRoute : catchRoute);
}

export function set404() {
	changePage('notFound');
}

export default connect(mapStateToProps)(Navigator);