module.exports = {
	if: {
		title: "IF Performance Hall",
		type: "Ticketing and Online Wallet",
		url: "https://www.ifperformance.com/",
		date: "Jun. 2023",

		description: `<p>IF Performance Hall is one of the biggest entertainment enterprises in Turkey.</p><p>This project provides a corporate page, a ticketing app and an online wallet for events made by IF Performance Hall. The wallet feature is only exposed when the project is run within the <a href="https://ifperformance.com/download" target="_blank" rel="noopener noreferrer">contained app</a>.</p>`,
		client: {
			name: "TLA",
			url: "https://wearetla.com",
		},
		participation: "Front-end development, entire project",
		stack: ["React.js", "Isomorphic React", "In-App Experience"]
	},
	// bbt_bms: {
	// 	title: "Big Bus Tours Booking Management System",
	// 	type: "Booking Management",
	// 	url: "https://www.bigbustours.com/manage-my-booking",
	// 	date: "Jan. 2023",

	// 	description: `<p>BBT Booking Management System is a standalone application within Big Bus Tours site communicating with both Magento backend and Ventrata API, allowing users to manage their bookings, pay to upgrade tickets and much more.</p>`,
	// 	client: {
	// 		name: "JH",
	// 		url: "https://wearejh.com",
	// 	},
	// 	participation: "Front-end development, team member.",
	// 	stack: ["React.js", "GraphQl", "In-App Experience"]
	// },
	// bargainmax: {
	// 	title: "Bargain Max",
	// 	type: "E-Commerce",
	// 	url: "https://bargainmax.co.uk/",
	// 	date: "Dec. 2022",

	// 	description: `<p>BargainMax.co.uk is the home of cheap toys, games and kids homewares online for children of all ages.</p><p>The project is based on JH e-commerce infastructure "AXEL" using Next.js and GraphQL.</p>`,
	// 	client: {
	// 		name: "JH",
	// 		url: "https://wearejh.com",
	// 	},
	// 	participation: "Front-end development, team member",
	// 	stack: ["React.js", "Next.js", "GraphQl"]
	// },
	metro: {
		title: "Metro Online Turkey",
		type: "E-commerce",
		url: "https://online.metro-tr.com/",
		date: "Mar. 2022",

		description: `<p>Metro Online Turkey is the localized online delivery platform made for the German multinational retailer Metro. The project supports isomorphic rendering allows users to navigate through the website like it's a plain SPA, and rendering cached pages on the server for the initial render. Providing both performance and flexibility.</p>`,
		client: {
			name: "Positive a Digital Approach",
			url: "https://positive.com.tr",
		},
		participation: "Front-end development, entire project",
		stack: ["React.js", "Isomorphic React"]
	},
	babymall: {
		title: "Baby Mall",
		type: "E-commerce",
		url: "https://www.babymall.com.tr/",
		date: "Jun. 2020",

		description: `<p>Baby Mall is a mother & baby focused retailer based in Turkey. We have re-designed the whole e-commerce infastructure as Positive a Digital Approach. Baby Mall on the front-end side, is an isomorphic React.js app.</p>`,
		client: {
			name: "Positive a Digital Approach",
			url: "https://positive.com.tr",
		},
		participation: "Front-end development, entire project",
		stack: ["React.js", "Isomorphic React"]
	},
	ceptesok: {
		title: "Cepte Şok",
		type: "E-commerce",
		url: "https://www.sokmarket.com.tr/",
		date: "Aug. 2020",

		description: `<p>Cepte Şok is a click&collect e-commerce web application made for Şok supermarket chain, one of the biggest discount supermarkets in Turkey. (As Positive a Digital Approach) We have re-designed and re-coded the entire front-end with a more recent stack to implement extended features.</p>`,
		client: {
			name: "Positive a Digital Approach",
			url: "https://positive.com.tr",
		},
		participation: "Front-end development, entire project",
		stack: ["React.js", "Redux"]
	},
	// thinkerfox: {
	// 	title: "Thinkerfox",
	// 	type: "Corporate web page",
	// 	url: "https://thinkerfox.com",
	// 	date: "Oct. 2019",

	// 	description: `<p>Thinkerfox is a software company providing data, consultancy, plannign and development services. I have initialized the Thinkerfox web page project from scratch, and delivered it with dummy content through JSON endpoints for future API implementions.</p>`,
	// 	client: {
	// 		name: "Thinkerfox",
	// 		url: "https://thinkerfox.com",
	// 	},
	// 	participation: "Front-end development, entire project",
	// 	stack: ["React.js", "Redux"]
	// },
	optdcom: {
		title: "Optdcom",
		type: "Corporate web page",
		url: "http://optdcom.net",
		date: "Sept. 2019",

		description: `<p>Optdcom is a digital marketing agency with data and technology at its core. I have initialized and delivered the Optdcom with dummy content through JSON endpoints for future API implementions.</p>`,
		client: {
			name: "Optdcom",
			url: "http://optdcom.net",
		},
		participation: "Front-end development, entire project",
		stack: ["React.js", "Redux"]
	},
	budunyahepimizin: {
		title: "Bu Dünya Hepimizin",
		type: "Corporate social responsibility project web page",
		url: "https://budunyahepimizin.org",
		date: "Sept. 2019",

		description: `
			<p>Bu dünya hepimizin is a social responsibility project developed by Kahve Dünyası, one of the biggest coffee franchises in Turkey.</p>
			<p>I have worked on this project as an employee of Positive a Digital Approach. I have built the front-end on a Laravel project by myself from scratch in order to save implementation time.</p>`,
		client: {
			name: "Positive a Digital Approach",
			url: "https://positive.com.tr",
		},
		participation: "Front-end development, entire project",
		stack: ["Laravel", "jQuery"]
	},
	minoto:	{
		title: "Minoto",
		type: "E-commerce",
		url: "https://minoto.com",
		date: "July. 2019",

		description: `<p>Minoto is an online car marketplace where only authorized dealers and only new vehicles are displayed with real photos and prices. I have formed the the whole UX and API structure from scratch. Worked simultaneously with Thinkerfox (the client) for a seamless integration.</p>`,
		client: {
			name: "Thinkerfox",
			url: "https://thinkerfox.com",
		},
		participation: "Front-end development, entire project",
		stack: ["React.js", "Redux"]
	},
	// logo: {
	// 	title: "Logo Software",
	// 	type: "Corporate web page",
	// 	url: "https://www.logo.com.tr",
	// 	date: "Jan. 2019",

	// 	description: `
	// 		<p>HRD Antwerp is a commercial organization with six different branches: Diamond Lab, Precious Stones Lab, Research, Education, Graduates Club and Equipment.</p>
	// 		<p>I have formed the the whole UX and API structure from scratch, and delivered it with dummy content through JSON endpoints for future API implementions.</p>`,
	// 	client: {
	// 		name: "Positive a Digital Approach",
	// 		url: "https://positive.com.tr",
	// 	},
	// 	participation: "Front-end development, entire project",
	// 	stack: ["jQuery"]
	// },
	eventpay: {
		title: "Event Pay Pro",
		type: "Application interface",
		url: [
			"https://play.google.com/store/apps/developer?id=EventPayPro&hl=en",
			"https://apps.apple.com/tr/developer/positive-a-digitalapproach/id914886493"
		],
		date: "Sept. 2019",

		description: `
			<p>Event Pay Pro is an integrated payment solution additionally offering customized apps for events and organizations. The webview interface is designed to meet the various requirements for specific events, festivals or places.</p>
			<p>Initially working on a single core, Event Pay Pro webview interface offers customized UI for each client. I have formed the base of the app interface, created a modular light weight structure in order to provide customized themes for customers. Still providing additional development for new clients.</p>`,
		client: {
			name: "Positive a Digital Approach",
			url: "https://positive.com.tr",
		},
		participation: "Front-end development",
		stack: ["Vanilla JavaScript", "SASS"]
	},
	kureselcalismalar: {
		title: "Küresel Çalışmalar",
		date: "Jan. 2018",
		type: "Blog",
		url: "https://www.kureselcalismalar.com",

		description: `
			<p>Küresel Çalışmalar is an international relations project based on Marmara University (Turkey). The web page is a blog, and an archive for the quarterly project publications.</p>
			<p>This is a one person project, I have single handedly worked on UI design, backend (WordPress) and Front-end development. I have also designed some print material for the association.</p>
		`,
		client: {
			name: "Küresel Çalışmalar",
			url: "https://www.kureselcalismalar.com",
		},
		participation: "Full-stack development, entire project.",
		stack: ["Wordpress", "jQuery"]
	},
	// polat: {
	// 	title: "Polat Holding",
	// 	date: "Feb. 2017",
	// 	type: "Corporate web page",
	// 	url: "https://www.polat.com",

	// 	description: `
	// 		<p>Polat Holding is an established group with investments on real estate and energy. I have initialized the front-end on static files, delivered it for further development.</p>`,
	// 	client: {
	// 		name: "Positive a Digital Approach",
	// 		url: "https://positive.com.tr",
	// 	},
	// 	participation: "Front-end development",
	// 	stack: ["jQuery"]
	// },
	ordaf: {
		title: "ORDAF",
		date: "Sept. 2016",
		type: "Organizational web page & blog",
		url: "https://ordaf.org",

		description: `
			<p>ORDAF Web Page is the corporate web page and academic blog for Association of Researchers on the Middle East and Africa.</p>
			<p>This is a one person project, I have single handedly worked on UI design, backend (Wordpress) and Front-end development. I have also designed some print material for the association.</p>`,
		client: {
			name: "Marmara University",
			url: "https://www.marmara.edu.tr",
		},
		participation: "Full-stack development, entire project",
		stack: ["Wordpress", "jQuery"]
	},
	rpq: {
		title: "Rising Powers and Global Governance",
		date: "Sept. 2016",
		type: "Academic blog & archive",
		url: "https://risingpowersproject.com",

		description: `
			<p>RPiGG is an international relations project based on Marmara University (Turkey). The web page is a blog, and an archive for the quarterly project publications.</p>
			<p>This is a one person project, I have single handedly worked on UI design, backend (Wordpress) development and front-end development. I have also designed the layout for the quarterly.</p>`,
		client: {
			name: "Marmara University",
			url: "https://www.marmara.edu.tr",
		},
		participation: "Full-stack development, entire project",
		stack: ["Wordpress", "jQuery"]
	},
	// bks: {
	// 	title: "Beşiktaş Kültür Sanat",
	// 	date: "Jun. 2015",
	// 	type: "Culture & arts guide",
	// 	url: "http://besiktaskultursanat.com",

	// 	description: `
	// 		<p>BKS is an online event guide and culture blog made for Beşiktaş Municipality (İstanbul/Turkey). This project is based on WordPress.</p>
	// 		<p>I have worked on both backend (WordPress) and front-end development parts of this project.</p>`,
	// 	client: {
	// 		name: "F5 Communications Management",
	// 		url: "https://www.f5-pr.com",
	// 	},
	// 	participation: "Full-stack development",
	// 	stack: ["WordPress", "jQuery"]
	// },
}