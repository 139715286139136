import React from 'react'

// Deps
import Link from 'views/partials/link'

const About = (props) => {
	return (
		<article className={'page content about' + (props.show ? ' show' : '')}>
			<h1 className="content-title">About Me</h1>

			<div className="content-text wysiwyg">
				<p><strong>Hey there!</strong> <span role="img" aria-label="(Yunus waving at you)">👋</span></p>

				<p>I'm a Front-end Developer from İstanbul with a demonstrated history of working in the internet industry, experienced on e-commerce and SAAS projects.</p>

				<p><i>[AI Generated BS Comes here]</i></p>

				<p>Feel free to <Link href="contact">contact me</Link> if you'd like to work with me.</p>

				<p>Cheers!</p>
			</div>
		</article>
	)
}

export default About