import React from 'react'

// Deps
import Link from 'views/partials/link'

const Contact = (props) => {
	return (
		<article className={'page content contact' + (props.show ? ' show' : '')}>
			<h1 className="content-title">Contact</h1>

			<div className="content-text wysiwyg">
				<p>I'm currently based in İstanbul/Turkey.</p>
				<p>If you'd like to get in touch, an email is a great way to start a conversation. Just drop one on <Link type="a" href="mailto:yutonet@gmail.com">yutonet@gmail.com</Link>.</p>
			</div>
		</article>
	)
}

export default Contact