module.exports = {
	pages: {
		home: {
			path: "/",
			component: "Home",
			exact: true,
			linkTitle: "Home",
			showNav: false,
			title: "Hi!",
			descriptiopn: "Yunus Tabakoğlu personal web page",
			// meta: {
			// 	keywords: "Optdcom, SEO"
			// },
			// Bu şekilde head kısmına custom meta da eklenebilir.
			// head: [
			// 	{
			// 		key: "meta",
			// 		content: false,
			// 		props: {
			// 			name: "description",
			// 			content: "Minoto, Sıfır Otomobilin Yeni Adresi"
			// 		}
			// 	},
			// 	{
			// 		key: "description",
			// 		content: "Minoto, Sıfır Otomobilin Yeni Adresi",
			// 		props: {}
			// 	}
			// ],
		},
		about: {
			path: "/about",
			component: "About",
			exact: true,
			linkTitle: "About",
			title: "About Me",
			descriptiopn: "Brief stuff about me",
			// meta: {
			// 	keywords: "Optdcom, SEO"
			// },
			// Bu şekilde head kısmına custom meta da eklenebilir.
			// head: [
			// 	{
			// 		key: "meta",
			// 		content: false,
			// 		props: {
			// 			name: "description",
			// 			content: "Minoto, Sıfır Otomobilin Yeni Adresi"
			// 		}
			// 	},
			// 	{
			// 		key: "description",
			// 		content: "Minoto, Sıfır Otomobilin Yeni Adresi",
			// 		props: {}
			// 	}
			// ],
		},
		works: {
			path: "/works",
			component: "Works",
			exact: true,
			linkTitle: "Works",
			title: "Works",
			descriptiopn: "Projects I've worked on",
			// meta: {
			// 	keywords: "Optdcom, SEO"
			// },
			// Bu şekilde head kısmına custom meta da eklenebilir.
			// head: [
			// 	{
			// 		key: "meta",
			// 		content: false,
			// 		props: {
			// 			name: "description",
			// 			content: "Minoto, Sıfır Otomobilin Yeni Adresi"
			// 		}
			// 	},
			// 	{
			// 		key: "description",
			// 		content: "Minoto, Sıfır Otomobilin Yeni Adresi",
			// 		props: {}
			// 	}
			// ],
		},
		workDetail: {
			path: "/works/:slug",
			component: "WorkDetail",
			exact: true,
			linkTitle: "Work Detail",
			title: "Work",
			descriptiopn: "Details about the project",
			// meta: {
			// 	keywords: "Optdcom, SEO"
			// },
			// Bu şekilde head kısmına custom meta da eklenebilir.
			// head: [
			// 	{
			// 		key: "meta",
			// 		content: false,
			// 		props: {
			// 			name: "description",
			// 			content: "Minoto, Sıfır Otomobilin Yeni Adresi"
			// 		}
			// 	},
			// 	{
			// 		key: "description",
			// 		content: "Minoto, Sıfır Otomobilin Yeni Adresi",
			// 		props: {}
			// 	}
			// ],
		},
		contact: {
			path: "/contact",
			component: "Contact",
			exact: true,
			linkTitle: "Contact",
			title: "Contact Me",
			descriptiopn: "Interested in contacting me? Go ahead!",
			// meta: {
			// 	keywords: "Optdcom, SEO"
			// },
			// Bu şekilde head kısmına custom meta da eklenebilir.
			// head: [
			// 	{
			// 		key: "meta",
			// 		content: false,
			// 		props: {
			// 			name: "description",
			// 			content: "Minoto, Sıfır Otomobilin Yeni Adresi"
			// 		}
			// 	},
			// 	{
			// 		key: "description",
			// 		content: "Minoto, Sıfır Otomobilin Yeni Adresi",
			// 		props: {}
			// 	}
			// ],
		},
		notFound: {
			path: false,
			component: "NotFound",
			exact: false,
			linkTitle: "404",
			title: "Page not Found",
			status: 404,
		},
		error: {
			path: false,
			component: "ErrorPage",
			exact: false,
			linkTitle: "Something went wrong",
			title: "Something went wrong",
			status: 500,
		},
	}
}