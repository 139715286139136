import { combineReducers, createStore } from 'redux'
import siteReducer from 'store/site';
//import modalsReducer from 'store/modals';

const rootReducer = combineReducers({
	site: siteReducer,
	//modals: modalsReducer,
})

const store = createStore(rootReducer);

export default store;