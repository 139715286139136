import React from 'react'

// Deps
//import Link from 'views/partials/link'

const About = (props) => {
	return (
		<article className={'page content about' + (props.show ? ' show' : '')}>
			<h1 className="content-title">Page not Found</h1>

			<div className="content-text wysiwyg">
				<p>
					This is a 404 page <span role="img" aria-label="(Yunus waving at you)">🤷‍♂️</span>
				</p>
			</div>
		</article>
	)
}

export default About